<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <h5>판매 및 채권 현황</h5>
        <div>
          <span class="option_txt">조회 기간</span>
          <div>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                @change="changeStartDate($event)"
                :value="start_date"
              />
            </div>
            <p>~</p>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                @change="changeEndDate($event)"
                :value="end_date"
              />
            </div>
            <button class="btn_sub2" @click="search()">조회</button>
          </div>
        </div>
      </div>
      <h6>조회수 : {{ companyList.length }}건</h6>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <colgroup>
            <col v-for="(n, index) in 5" :key="index" />
          </colgroup>
          <thead>
            <tr>
              <th rowspan="2">NO</th>
              <th rowspan="2">거래처명</th>
              <th colspan="2">{{ end_date_temp }} 마감</th>
              <th colspan="2" class="text_right">
                &#8361;
                {{
                  end_date_temp == null
                    ? ''
                    : $makeComma(
                        companyList
                          .map(x => x.bond_value)
                          .reduce((a, b) => $decimalAdd(a, b), 0),
                      )
                }}
              </th>
            </tr>
            <tr>
              <th>매출</th>
              <th>수금액</th>
              <th>채권액</th>
              <th>미수금액</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in companyList" :key="item.company_id">
              <td>{{ index + 1 }}</td>
              <td class="text_left">
                {{ findInfoFromId(companys, item.company_id).name }}
              </td>
              <td class="text_right">
                {{
                  item.sales_value == 0
                    ? '-'
                    : '&#8361; ' + $makeComma(item.sales_value)
                }}
              </td>
              <td class="text_right">
                {{
                  item.collection_value == 0
                    ? '-'
                    : '&#8361; ' + $makeComma(item.collection_value)
                }}
              </td>
              <td class="text_right">
                {{
                  item.bond_value == 0
                    ? '-'
                    : '&#8361; ' + $makeComma(item.bond_value)
                }}
              </td>
              <td class="text_right">
                {{
                  item.sales_value - item.collection_value - item.bond_value ==
                  0
                    ? '-'
                    : '&#8361; ' +
                      $makeComma(
                        item.sales_value -
                          item.collection_value -
                          item.bond_value,
                      )
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { yyyymmdd } from '@/utils/func';
import MODAL_MIXIN from '@/mixins/modal';
import FETCH_MIXIN from '@/mixins/fetch';
import SPINNER_MIXIN from '@/mixins/spinner';
export default {
  mixins: [MODAL_MIXIN, FETCH_MIXIN, SPINNER_MIXIN],
  computed: {
    ...mapGetters({
      start_date: 'getStartDateFromSalesCollectionStatus',
      end_date: 'getEndDateFromSalesCollectionStatus',
      salesAccounts: 'getSalesAccountFromSalesCollectionStatus',
      collections: 'getCollectionFromSalesCollectionStatus',
      end_date_temp: 'getEndDateTempFromSalesCollectionStatus',
      collection_types: 'getCollectionType',
      companys: 'getCompany',
    }),
    companyList() {
      if (this.collections.length < 1 && this.salesAccounts.length < 1) {
        return [];
      } else {
        let accs = this.lodash.clonedeep(this.salesAccounts).map(x => {
          return {
            company_id: x.sales.company_id,
            sales_value: x.sales_value,
            collection_value: 0,
            bond_value: 0,
          };
        });

        let coll = this.lodash.clonedeep(this.collections).map(x => {
          return {
            company_id: x.company_id,
            sales_value: 0,
            collection_value: this.collection_types
              .filter(y => y.date_yn)
              .map(z => z.id)
              .includes(x.collection_type_id)
              ? 0
              : x.total_value,
            bond_value:
              this.collection_types
                .filter(y => y.date_yn)
                .map(z => z.id)
                .includes(x.collection_type_id) &&
              x.collecting_date != null &&
              this.end_date_temp >= x.collecting_date
                ? x.total_value
                : 0,
          };
        });
        const newArr = accs.concat(coll);
        const obj = newArr.reduce(function(rv, x) {
          (rv[x['company_id']] = rv[x['company_id']] || []).push(x);
          return rv;
        }, {});
        let arr = [];
        for (let k in obj) {
          arr.push({
            company_id: Number(k),
            sales_value: obj[k]
              .map(x => x.sales_value)
              .reduce((a, b) => this.$decimalAdd(a, b), 0),
            collection_value: obj[k]
              .map(x => x.collection_value)
              .reduce((a, b) => this.$decimalAdd(a, b), 0),
            bond_value: obj[k]
              .map(x => x.bond_value)
              .reduce((a, b) => this.$decimalAdd(a, b), 0),
          });
        }
        return arr.sort(
          (a, b) =>
            this.findInfoFromId(this.companys, a.company_id).name -
            this.findInfoFromId(this.companys, b.company_id).name,
        );
      }
    },
  },
  methods: {
    async search() {
      this.showSpinner();
      this.$store.commit(
        'setEndDateTempToSalesCollectionStatus',
        this.lodash.clonedeep(this.end_date),
      );
      await this.$store.dispatch(
        'FETCH_COLLECTION_FROM_SALE_COLLECTION_STATUS',
        {
          start: this.start_date,
          end: this.end_date,
        },
      );
      await this.$store.dispatch(
        'FETCH_SALES_ACCOUNT_DAY_ALL_TO_SALES_COLLECTION_STATUS',
        { start: this.start_date, end: this.end_date },
      );
      this.hideSpinner();
    },
    changeStartDate({ target }) {
      if (target.value != '') {
        if (this.end_date < target.value) {
          this.$store.commit('setEndDateToSalesCollectionStatus', target.value);
          this.$store.commit(
            'setStartDateToSalesCollectionStatus',
            target.value,
          );
        } else {
          this.$store.commit(
            'setStartDateToSalesCollectionStatus',
            target.value,
          );
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit(
          'setStartDateToSalesCollectionStatus',
          yyyymmdd(new Date()),
        );
      }
    },
    changeEndDate({ target }) {
      if (target.value != '') {
        if (this.start_date > target.value) {
          this.$store.commit('setEndDateToSalesCollectionStatus', target.value);
          this.$store.commit(
            'setStartDateToSalesCollectionStatus',
            target.value,
          );
        } else {
          this.$store.commit('setEndDateToSalesCollectionStatus', target.value);
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit(
          'setEndDateToSalesCollectionStatus',
          yyyymmdd(new Date()),
        );
      }
    },
  },
  async created() {
    if (this.collection_types.length < 1) {
      await this.FETCH('FETCH_COLLECTION_TYPE', '수금구분');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    if (this.start_date == null) {
      const s_date = new Date();
      const e_date = new Date();
      s_date.setMonth(s_date.getMonth() - 1);
      this.$store.commit(
        'setStartDateToSalesCollectionStatus',
        yyyymmdd(s_date),
      );
      this.$store.commit('setEndDateToSalesCollectionStatus', yyyymmdd(e_date));
    }
  },
};
</script>

<style scoped></style>
